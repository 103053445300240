import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { createToastInterface } from 'vue-toastification'

const pluginOptions = {
  hideProgressBar: true,
  closeOnClick: false,
  closeButton: false,
  icon: false,
  timeout: 3000,
  transition: 'Vue-Toastification__fade',
}

// Create the interface
const toast = createToastInterface(pluginOptions)

export default {
  success: message => toast({
    component: ToastificationContent,
    props: {
      title: message || 'Successful!',
      variant: 'success',
      icon: 'CheckIcon',
    },
  }),
  warning: message => (toast({
    component: ToastificationContent,
    props: {
      title: message || 'Warning!',
      icon: 'AlertTriangleIcon',
      variant: 'warning',
    },
  })),
  info: message => (toast({
    component: ToastificationContent,
    props: {
      title: message || 'Information!',
      icon: 'InfoIcon',
      variant: 'info',
    },
  })),
  error: message => (toast({
    component: ToastificationContent,
    props: {
      title: message || 'Error!',
      icon: 'XIcon',
      variant: 'danger',
    },
  })),
}
