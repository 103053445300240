<template>
  <b-card>
    <div class="d-flex justify-content-between">
      <p class="card-title ">
        Fine Management Config
      </p>
      <!--      <b-link-->
      <!--          variant="outline-info mx-2"-->
      <!--          type="button"-->
      <!--          @click="onActivityLog"-->
      <!--      >-->
      <!--        Activity log-->
      <!--      </b-link>-->
    </div>
    <div class="custom-search d-flex justify-content-between">
      <div />
      <!-- Session Filter Dropdown -->
      <b-form-group
        class="pr-0 mr-0 col-lg-3"
        label-for="pay-cycle-config-session"
      >
        <v-select
          v-model="sessionFilter"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="sessionOptions"
          :reduce="(val) => val.id"
          label="name"
          placeholder="Select Session"
          @input="changeSession"
        />
      </b-form-group>
    </div>

    <b-form @submit.prevent>
      <!-- @submit="onSubmit" -->
      <div>
        <!-- TODO::Section for School Absebce -->
        <b-row>
          <b-col>
            <b-form-group
              class="ml-5"
              label="School Absence Fine"
              label-cols-md="4"
            >
              <b-form-checkbox
                v-model="schoolAbsenceCheck"
                unchecked-value="not_checked"
                value="checked"
                @change="checkSA"
              />
              <!-- {{ schoolAbsenceCheck }} -->
            </b-form-group>
          </b-col>
        </b-row>

        <div
          v-if="schoolAbsenceCheck==='checked'"
          class="ml-5"
        >
          <b-row>
            <b-col>
              <b-form-group
                :state="finePerDayStateSA"
                class="ml-5"
                invalid-feedback="Fine Per Day is required"
                label="Fine Per Day"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="schoolAbsence.finePerDay"
                  :state="finePerDayStateSA"
                  class="col-md-9"
                  placeholder="Input Fine"
                  type="number"
                />
                <!-- {{ paymentCycleLateFineCheck }} -->
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                class="ml-5"
                label="Payment Cycle Exception"
                label-cols-md="4"
              >
                <v-select
                  v-model="schoolAbsence.paymentCycleIds"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="paymentCycleOptions"
                  :reduce="(val) => val.id"
                  class="col-md-9 p-0"
                  label="cycle_name"
                  multiple
                  placeholder="Select Payment Cycle Exception"
                />
                <!-- {{ paymentCycleLateFineCheck }} -->
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                class="ml-5"
                label="Class Exception"
                label-cols-md="4"
              >
                <v-select
                  v-model="schoolAbsence.classIds"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="classOptions"
                  :reduce="(val) => val.id"
                  class="col-md-9 p-0"
                  label="name"
                  multiple
                  placeholder="Select Class Exception"
                />
                <!-- {{ paymentCycleLateFineCheck }} -->
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <!-- TODO::Section for School Absebce -->

        <!-- TODO::Section for paymentCycle Late Fine -->
        <b-row>
          <b-col>
            <b-form-group
              class="ml-5"
              label="Payment Cycle Late Fine"
              label-cols-md="4"
            >
              <b-form-checkbox
                v-model="paymentCycleLateFineCheck"
                unchecked-value="not_checked"
                value="checked"
                @change="checkPCLF"
              />
              <!-- {{ paymentCycleLateFineCheck }} -->
            </b-form-group>
          </b-col>
        </b-row>

        <div
          v-if="paymentCycleLateFineCheck==='checked'"
          class="ml-5"
        >
          <b-row
            v-for="(lateFineConfig, index) in lateFineConfigs"
            :id="lateFineConfig.id"
            :key="lateFineConfig.id"
            ref="lateFineConfigRow"
          >
            <b-col>
              <b-form-group class="ml-5">
                <div class="d-flex mb-2">
                  <div class="mr-1">
                    Amount
                  </div>
                  <b-form-input
                    v-model="lateFineConfig.amount"
                    class="col-md-2 mr-1"
                    placeholder="XXXX"
                    type="number"
                  />
                  <div class="mr-1">
                    will be applied for
                  </div>
                  <b-form-input
                    v-model="lateFineConfig.dayWeekTime"
                    class="col-md-1 mr-1"
                    placeholder="X"
                    type="number"
                  />
                  <v-select
                    v-model="lateFineConfig.dayWeekValue"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="dayWeekOptions"
                    :reduce="(val) => val.id"
                    class="col-md-3 p-0"
                    label="name"
                    placeholder="Day/Week"
                  />
                  <div
                    v-if="lateFineConfigs.length-1 === index"
                    class="ml-1"
                  >
                    <b-form-checkbox
                      v-model="lateFineConfig.continue"
                      unchecked-value="not_checked"
                      value="checked"
                    >
                      Will Continue
                    </b-form-checkbox>
                  </div>
                </div>
              </b-form-group>

            </b-col>
            <!-- Remove Button -->
            <b-col cols="2">
              <b-button
                v-if="lateFineConfig.id == 1"
                class="mr-1"
                variant="primary"
                @click="repeatLateFineConfig"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
              <b-button
                v-if="lateFineConfig.id != 1"
                variant="outline-danger"
                @click="removeLateFineConfigRow(index)"
              >
                <feather-icon icon="MinusIcon" />
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                class="ml-5"
                :state="amountPCLFState"
                invalid-feedback="Amount is required"
              >
                <div class="d-flex mb-2">
                  <div class="mr-2">
                    Fine will not be applied if
                  </div>
                  <b-form-input
                    v-model="paymentCycleLateFine.amount"
                    class="col-md-2 mr-2"
                    placeholder="XXXX"
                    type="number"
                    :state="amountPCLFState"
                  />
                  <div class="mr-2">
                    % of total amount is paid
                  </div>

                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                class="ml-5"
                label="Payment Cycle Exception"
                label-cols-md="4"
              >
                <v-select
                  v-model="paymentCycleLateFine.paymentCycleIds"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="paymentCycleOptions"
                  :reduce="(val) => val.id"
                  class="col-md-9"
                  label="cycle_name"
                  multiple
                  placeholder="Select Payment Cycle Exception"
                />
                <!-- {{ paymentCycleLateFineCheck }} -->
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                class="ml-5"
                label="Class Exception"
                label-cols-md="4"
              >
                <v-select
                  v-model="paymentCycleLateFine.classIds"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="classOptions"
                  :reduce="(val) => val.id"
                  class="col-md-9"
                  label="name"
                  multiple
                  placeholder="Select Class Exception"
                />
                <!-- {{ paymentCycleLateFineCheck }} -->
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <!-- TODO::Section for paymentCycle Late Fine-->

        <!-- TODO::Section for Re Admission Fine-->
        <b-row>
          <b-col>
            <b-form-group
              class="ml-5"
              label="Re Admission Fine"
              label-cols-md="4"
            >
              <b-form-checkbox
                v-model="reAdminnsionFineCheck"
                unchecked-value="not_checked"
                value="checked"
                @change="checkRAF"
              />
              <!-- {{ reAdminnsionFineCheck }} -->
            </b-form-group>
          </b-col>
        </b-row>

        <div
          v-if="reAdminnsionFineCheck==='checked'"
          class="ml-5 mb-3"
        >
          <b-row>
            <b-col>
              <b-form-group
                :state="monthStateRAF"
                class="ml-5"
                invalid-feedback="Month is required"
              >
                <div class="d-flex">
                  <div class="mr-2">
                    If the student is defaulter for
                  </div>
                  <b-form-input
                    v-model="reAdminnsionFine.month"
                    :state="monthStateRAF"
                    class="col-md-2 mr-2"
                    placeholder="XXXX"
                    type="number"
                  />
                  <div class="mr-2">
                    Month
                  </div>

                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="ml-3 mt-1">
            <b-col>
              <b-form-group
                :state="checkStateRAF"
                class="ml-5"
                invalid-feedback="Please choose one"
              >

                <b-form-checkbox
                  v-model="amount_taka"
                  :state="checkStateRAF"
                  unchecked-value="not_checked"
                  value="checked"
                  @change="amountTaka"
                >
                  <div class="d-flex mb-2">
                    <div class="mr-2">
                      Amount
                    </div>
                    <b-form-group
                      :state="amountStateRAF"
                      invalid-feedback="Amount is required"
                    >
                      <b-form-input
                        v-model="reAdminnsionFine.amount"
                        :state="amountStateRAF"
                        class="col-md-4 mr-2"
                        placeholder="XXX"
                        type="number"
                      />
                    </b-form-group>

                    <div class="mr-2">
                      Taka
                    </div>

                  </div>
                </b-form-checkbox>
                <!-- {{ paymentCycleLateFineCheck }} -->
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="ml-3">
            <b-col>
              <b-form-group
                :state="checkStateRAF"
                class="ml-5"
                invalid-feedback="Please choose one"
              >

                <b-form-checkbox
                  v-model="amount_equivalent"
                  :state="checkStateRAF"
                  unchecked-value="not_checked"
                  value="checked"
                  @change="amountEquivalent"
                >
                  <div class="d-flex mb-2">
                    <div class="mr-2">
                      Amount equivalent to monthly Tuition fee
                    </div>

                  </div>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                class="ml-5"
                label="Payment Cycle Exception"
                label-cols-md="4"
              >
                <v-select
                  v-model="reAdminnsionFine.paymentCycleIds"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="paymentCycleOptions"
                  :reduce="(val) => val.id"
                  class="col-md-9"
                  label="cycle_name"
                  multiple
                  placeholder="Select Payment Cycle Exception"
                />
                <!-- {{ paymentCycleLateFineCheck }} -->
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                class="ml-5"
                label="Class Exception"
                label-cols-md="4"
              >
                <v-select
                  v-model="reAdminnsionFine.classIds"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="classOptions"
                  :reduce="(val) => val.id"
                  class="col-md-9"
                  label="name"
                  multiple
                  placeholder="Select Class Exception"
                />
                <!-- {{ paymentCycleLateFineCheck }} -->
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <!-- TODO::Section for Re Admission Fine-->

        <b-form-group
          class="mt-5"
          label-cols-md="4"
        >
          <b-button
            class="mr-1"
            type="submit"
            variant="success"
            @click.prevent="saveButton"
          >
            <!-- @click.prevent="collectNowConFinalfirmationFunction" -->

            Save
          </b-button>
          <b-button
            class="mr-1"
            type="submit"
            variant="danger"
          >
            Cancel
          </b-button>
        </b-form-group>
      </div>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BCard, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BRow, VBModal,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import vSelect from 'vue-select'
import store from '@/store/index'
// import { RefreshCwIcon } from 'vue-feather-icons'
import FemsToastrService from '@/@service/utils/FemsToastr.service'

export default {
  components: {
    BCard,
    // VueGoodTable,
    // BPagination,
    BForm,
    BFormGroup,
    BFormInput,
    // BFormSelect,
    BFormCheckbox,
    BRow,
    BCol,
    vSelect,
    BButton,
    // RefreshCwIcon,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      amount_taka: 'not_checked',
      amount_equivalent: 'not_checked',
      //   TODO:: New data for config fine
      schoolAbsenceCheck: 'not_checked',
      paymentCycleLateFineCheck: 'not_checked',
      reAdminnsionFineCheck: 'not_checked',

      schoolAbsence: {},
      paymentCycleLateFine: {},
      reAdminnsionFine: {},

      finePerDayStateSA: null,
      monthStateRAF: null,
      checkStateRAF: null,
      amountStateRAF: null,
      amountPCLFState: null,

      classOptions: [],
      paymentCycleOptions: [],
      lateFineConfigData: {},
      dayWeekOptions: [
        { id: 1, name: 'Days' },
        { id: 2, name: 'Weeks' },
      ],

      lateFineConfigs: [{
        id: 1,
        dayWeekTime: '',
        amount: '',
        dayWeekValue: '',
        continue: '',
      }],
      nextLateFineConfigId: 2,

      // session data
      sessionOptions: [],
      sessionFilter: null,
      //   TODO:: New data for config fine
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {},
  created() {
    setTimeout(() => {
      this.getSessionLists()
    }, 500)
  },
  mounted() {
    // this.getSessionLists()
  },
  methods: {
    /*
    * check box operations for Re Admission Fine
    */
    amountTaka() {
      this.checkStateRAF = null
      this.amount_taka = 'checked'
      this.amount_equivalent = 'not_checked'
    },
    amountEquivalent() {
      this.checkStateRAF = null
      this.amount_taka = 'not_checked'
      this.amount_equivalent = 'checked'
      this.reAdminnsionFine.amount = null
    },

    checkSA() {
      if (this.schoolAbsenceCheck === 'checked') {
        this.getPaymentCycle()
        this.getClassLists()
      }

      // if (this.schoolAbsenceCheck === 'not_checked') {
      //   this.finePerDayStateSA = null
      //   this.schoolAbsence.finePerDay = null
      //   this.schoolAbsence.paymentCycleIds = []
      //   this.schoolAbsence.classIds = []
      // }
    },
    checkPCLF() {
      // if (this.paymentCycleLateFineCheck === 'not_checked') {
      //   this.amountPCLFState = null
      //   this.paymentCycleLateFine.amount = null
      //   this.lateFineConfigs = [{
      //     id: 1,
      //     dayWeekTime: '',
      //     amount: '',
      //     dayWeekValue: '',
      //     continue: '',
      //   }]
      //   this.paymentCycleLateFine.paymentCycleIds = []
      //   this.paymentCycleLateFine.classIds = []
      // }

      if (this.paymentCycleLateFineCheck === 'checked') {
        this.getPaymentCycle()
        this.getClassLists()
      }
    },
    checkRAF() {
      // if (this.reAdminnsionFineCheck === 'not_checked') {
      //   this.monthStateRAF = null
      //   this.checkStateRAF = null
      //   this.amountStateRAF = null
      //
      //   this.reAdminnsionFine.month = null
      //   this.amount_taka = 'not_checked'
      //   this.reAdminnsionFine.amount = null
      //   this.amount_equivalent = 'not_checked'
      //   this.reAdminnsionFine.paymentCycleIds = []
      //   this.reAdminnsionFine.classIds = []
      // }

      if (this.reAdminnsionFineCheck === 'checked') {
        this.getPaymentCycle()
        this.getClassLists()
      }
    },
    changeSession() {
      if (!this.sessionFilter) {
        FemsToastrService.error('Please select a session.')
        return
      }
      this.read()
    },
    /*
    * check box operations for Re Admission Fine
    */

    /*
    * get data from apis (session, class)
    */
    getSessionLists() {
      const token = localStorage.getItem('accessToken')
      this.$http.get(`${window.apiUrl}accounting/config-fine-settings/session`, {
        headers: {
          Authorization: `Bearer ${token}`, // replace 'token' with your actual token
          'Content-Type': 'application/json',
          // add more headers as needed
        },
      })
        .then(res => {
          if (res.data.status) {
            this.sessionOptions = res.data.data
            this.sessionFilter = this.sessionOptions.find(x => x.is_current === 1).id
            this.read()
            if (!this.sessionFilter) {
              FemsToastrService.error('Current Session Info not found')
            }
          }
        })
    },

    getPaymentCycle() {
      // eslint-disable-next-line radix
      const secSessionId = parseInt(this.sessionFilter)
      const token = localStorage.getItem('accessToken')
      this.$http.get(`${window.apiUrl}accounting/config-fine-settings/get-payment-cycle/${secSessionId}`, {
        headers: {
          Authorization: `Bearer ${token}`, // replace 'token' with your actual token
          'Content-Type': 'application/json',
          // add more headers as needed
        },

      })
        .then(res => {
          if (res.status) {
            if (res.data.status) {
              this.paymentCycleOptions = res.data.data
            } else {
              this.paymentCycleOptions = []
            }
          } else {
            FemsToastrService.error(res.data.msg)
          }
        })
    },
    getClassLists() {
      const token = localStorage.getItem('accessToken')
      this.$http.get(`${window.apiUrl}accounting/config-fine-settings/class`, {
        headers: {
          Authorization: `Bearer ${token}`, // replace 'token' with your actual token
          'Content-Type': 'application/json',
          // add more headers as needed
        },

      })
        .then(res => {
          if (res.status) {
            if (res.data.status) {
              this.classOptions = res.data.data
              // this.sessionFilter = this.sessionOptions.find(x => x.is_current === 1).id
            } else {
              this.classOptions = []
            }
          } else {
            FemsToastrService.error(res.data.msg)
          }
        })
    },
    /*
    * get data from apis (session, class)
    */

    /*
    * configuration function
    */
    repeatLateFineConfig() {
      let errorMessage = null
      // eslint-disable-next-line no-unused-vars
      let error = false

      const fineConfig = {}
      this.lateFineConfigs.forEach(item => {
        if (!item.amount || item.amount === '' || item.amount === 0) {
          error = true
          errorMessage = 'Please specify a valid amount!'
        } else if (!item.dayWeekTime || item.dayWeekTime === '' || item.dayWeekTime === 0) {
          error = true
          errorMessage = 'Please specify a Time'
        } else if (!item.dayWeekValue || item.dayWeekValue === '' || item.dayWeekTime === 0) {
          error = true
          errorMessage = 'Please specify a valid Day/Week'
        } else {
          fineConfig[item.amount] = item.amount
          fineConfig[item.dayWeekTime] = item.dayWeekTime
          fineConfig[item.dayWeekValue] = item.dayWeekValue
          if (item.continue === 'checked') {
            fineConfig[item.continue] = 1
          } else if (item.continue === 'not_checked' || item.continue === null || item.continue === '') {
            fineConfig[item.continue] = 0
          }
        }
      })

      if (errorMessage) {
        FemsToastrService.error(errorMessage)
        return
      }
      FemsToastrService.error('New Row Addes')

      this.lateFineConfigs.push({
        id: this.nextLateFineConfigId += this.nextLateFineConfigId,
      })
      // this.lateFineConfigData.push({
      //   amount: this.lateFineConfig.amount,
      //   dayWeekTime: this.lateFineConfig.dayWeekTime,
      //   dayWeekValue: this.lateFineConfig.dayWeekValue,

      // })
    },
    removeLateFineConfigRow(index) {
      this.lateFineConfigs.splice(index, 1)
    },
    deletePcConfig(rowData) {
      this.lateFineConfigData.splice(rowData.originalIndex, 1)
    },
    /*
    * configuration function
    */

    /*
    * for validation and save operations
    */
    formValidation() {
      // this.schoolAbsenceCheck = 'not_checked'
      // this.paymentCycleLateFineCheck = 'not_checked'
      // this.reAdminnsionFineCheck = 'not_checked'

      this.finePerDayStateSA = null
      this.monthStateRAF = null
      this.checkStateRAF = null
      this.amountStateRAF = null

      this.amountPCLFState = null
      // school Absence Check
      if (this.schoolAbsenceCheck === 'checked') {
        if (!this.schoolAbsence.finePerDay
            || this.schoolAbsence.finePerDay === '') {
          this.finePerDayStateSA = false
        }
        if (this.finePerDayStateSA !== false) {
          return true
        }
        return false
      }
      // paymentCycle Late Fine Check
      if (this.paymentCycleLateFineCheck === 'checked') {
        let errorMessage = null
        let error = false
        if (this.lateFineConfigs && this.lateFineConfigs.length > 0) {
          this.lateFineConfigs.forEach(item => {
            if (!item.amount || item.amount === '' || item.amount === 0) {
              error = true
              errorMessage = 'Please specify a valid amount in paymentCycle late fine config!'
            } else if (!item.dayWeekTime || item.dayWeekTime === '' || item.dayWeekTime === 0) {
              error = true
              errorMessage = 'Please specify a Time in paymentCycle late fine config!'
            } else if (!item.dayWeekValue || item.dayWeekValue === '' || item.dayWeekTime === 0) {
              error = true
              errorMessage = 'Please specify a valid Day/Week in paymentCycle late fine config!'
            } else {
              error = false
            }
          })
        }
        if (!this.paymentCycleLateFine.amount || this.paymentCycleLateFine.amount === '') {
          this.amountPCLFState = false
        }
        if (error === true) {
          FemsToastrService.error(errorMessage)
        }
        if (this.amountPCLFState !== false) {
          return true
        }
        return false
      }

      // re Admission Fine Check
      if (this.reAdminnsionFineCheck === 'checked') {
        if (!this.reAdminnsionFine.month
            || this.reAdminnsionFine.month === '') {
          this.monthStateRAF = false
        }

        if ((this.amount_taka === 'not_checked')
            && (this.amount_equivalent === 'not_checked')) {
          this.checkStateRAF = false
        } else if (this.amount_taka === 'checked') {
          this.checkStateRAF = null
          if (!this.reAdminnsionFine.amount || this.reAdminnsionFine.amount === '') {
            this.amountStateRAF = false
          }
        } else if (this.amount_equivalent === 'checked') {
          this.checkStateRAF = null
        }

        //
        if (this.monthStateRAF !== false && this.amountStateRAF !== false && this.checkStateRAF !== false) {
          return true
        }
        return false
      }
      return true
    },
    saveButton() {
      if (!this.formValidation()) {
        return
      }
      const SAF = {
        amount: this.schoolAbsence.finePerDay ? this.schoolAbsence.finePerDay : null,
        payment_cycle_ids: this.schoolAbsence.paymentCycleIds ? this.schoolAbsence.paymentCycleIds : [],
        class_ids: this.schoolAbsence.classIds ? this.schoolAbsence.classIds : [],
      }

      const PCLF = {
        amount: this.paymentCycleLateFine.amount ? this.paymentCycleLateFine.amount : 0,
        late_fine_configs: this.lateFineConfigs,
        payment_cycle_ids: this.paymentCycleLateFine.paymentCycleIds ? this.paymentCycleLateFine.paymentCycleIds : [],
        class_ids: this.paymentCycleLateFine.classIds ? this.paymentCycleLateFine.classIds : [],
      }

      const RAF = {
        defaulter_month: this.reAdminnsionFine.month ? this.reAdminnsionFine.month : null,
        amount_taka_check: this.amount_taka === 'checked' ? 1 : 0,
        amount: this.amount_taka === 'checked' ? this.reAdminnsionFine.amount : 0,
        amount_equivalent_check: this.amount_equivalent === 'checked' ? 1 : 0,
        payment_cycle_ids: this.reAdminnsionFine.paymentCycleIds ? this.reAdminnsionFine.paymentCycleIds : [],
        class_ids: this.reAdminnsionFine.classIds ? this.reAdminnsionFine.classIds : [],
      }
      const token = localStorage.getItem('accessToken')
      const params = {
        school_absence_fine: this.schoolAbsenceCheck === 'checked' ? 1 : 0,
        school_absence_fine_data: SAF,
        payment_cycle_late_fine: this.paymentCycleLateFineCheck === 'checked' ? 1 : 0,
        payment_cycle_late_fine_data: PCLF,
        re_admission_fine: this.reAdminnsionFineCheck === 'checked' ? 1 : 0,
        re_admission_fine_data: RAF,
        sec_session_id: this.sessionFilter,
      }
      this.$http.post(`${window.apiUrl}accounting/config-fine-settings/store`, params, {
        headers: {
          Authorization: `Bearer ${token}`, // replace 'token' with your actual token
          'Content-Type': 'application/json',
          // add more headers as needed
        },
      })
        .then(res => {
          if (res.status) {
            if (res.data.status) {
              this.read()
              FemsToastrService.success(res.data.msg)
            }
          } else {
            FemsToastrService.error(res.data.msg)
          }
        })
    },
    /*
    * for validation and save operations
    */

    /*
    * read calls
    */

    read() {
      this.getClassLists()
      this.getPaymentCycle()
      // eslint-disable-next-line radix
      const secSessionId = parseInt(this.sessionFilter)
      const token = localStorage.getItem('accessToken')
      this.$http.get(`${window.apiUrl}accounting/config-fine-settings/read/${secSessionId}`, {
        headers: {
          Authorization: `Bearer ${token}`, // replace 'token' with your actual token
          'Content-Type': 'application/json',
          // add more headers as needed
        },

      })
        .then(res => {
          if (res.status) {
            if (res.data.status) {
              FemsToastrService.success(res.data.msg)
              if (res.data.data.has_absence_fine === 1) {
                this.schoolAbsenceCheck = 'checked'
                const config = JSON.parse(res.data.data.absence_fine_data)
                this.schoolAbsence.finePerDay = config.amount
                this.schoolAbsence.paymentCycleIds = config.payment_cycle_ids
                this.schoolAbsence.classIds = config.class_ids
              }
              if (res.data.data.has_payment_cycle_late_fine === 1) {
                this.paymentCycleLateFineCheck = 'checked'
                const config = JSON.parse(res.data.data.payment_cycle_late_fine_data)
                this.paymentCycleLateFine.amount = config.amount
                this.lateFineConfigs = config.late_fine_configs
                this.paymentCycleLateFine.paymentCycleIds = config.payment_cycle_ids
                this.paymentCycleLateFine.classIds = config.class_ids
              }
              if (res.data.data.has_readmission_fine === 1) {
                this.reAdminnsionFineCheck = 'checked'
                const config = JSON.parse(res.data.data.readmission_fine_data)
                this.reAdminnsionFine.month = config.defaulter_month
                this.amount_taka = config.amount_taka_check
                this.reAdminnsionFine.amount = config.amount
                this.amount_equivalent = config.amount_equivalent_check
                this.reAdminnsionFine.paymentCycleIds = config.payment_cycle_ids
                this.reAdminnsionFine.classIds = config.class_ids
              }
              this.sessionFilter = res.data.data.sec_aca_session_id
            } else {
              this.resetPage()
            }
          } else {
            FemsToastrService.error(res.data.msg)
          }
        })
    },
    resetPage() {
      this.schoolAbsenceCheck = 'not_checked'
      this.schoolAbsence.finePerDay = null
      this.schoolAbsence.paymentCycleIds = []
      this.schoolAbsence.classIds = []

      this.paymentCycleLateFineCheck = 'not_checked'
      this.paymentCycleLateFine.amount = null
      this.lateFineConfigs = this.late_fine_configs
      this.paymentCycleLateFine.paymentCycleIds = []
      this.paymentCycleLateFine.classIds = []

      this.reAdminnsionFineCheck = 'not_checked'
      this.reAdminnsionFine.month = null
      this.amount_taka = 'not_checked'
      this.reAdminnsionFine.amount = null
      this.amount_equivalent = 'not_checked'
      this.reAdminnsionFine.paymentCycleIds = []
      this.reAdminnsionFine.classIds = []
    },
    /*
    * read calls
    */

    // TODO:: New code for fine management
    openFineModal() {
      this.$bvModal.show('fine-modal')
    },
    // TODO:: New code for fine management
  },
}
</script>
<style lang="scss">
svg.feather.feather-refresh-cw {
  height: 15px;
  width: 15px;
}
</style>
